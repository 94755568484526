.viewclientsessionhis-main .viewsession-main {
    background-color: var(--white);
    border-radius: 20px;
    border: 1px solid var(--lightBorderColor);
}

.viewclientsessionhis-main .viewsession-header {
    background-color: var(--lightWhite);
    padding: 10px 0px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 1px solid var(--lightBorderColor);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.viewclientsessionhis-main .viewsession-header p {
    margin-bottom: 0;
    font-weight: 600;
}

.viewclientsessionhis-main .viewsession-main .viewsession-body {
    padding: 20px;
}

.viewclientsessionhis-main .viewsession-main .viewsession-body .menu-main {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.viewclientsessionhis-main .viewsession-main .viewsession-body .menu-main label {
    cursor: pointer;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    padding: 0;
    margin-left: 30px;
    font-weight: 600;

}

.viewclientsessionhis-main .viewsession-main .viewsession-body .menu-main input[type='radio']:checked+label {
    border-color: var(--borderOrange);
}

.viewclientsessionhis-main .viewsession-main .viewsession-body .menu-main input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    top: 7px;
    width: 20px;
}

.viewclientsessionhis-main .viewsession-main .viewsession-body .menu-main .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid var(--headerTextColor);
    background-color: transparent;
}

.viewclientsessionhis-main .viewsession-main .menu-main .checkmark.active {
    background-color: var(--greenTextcolor);
    border: none;
}


.viewclientsessionhis-main .viewsession-main .viewsession-body .menu-main .input-main {
    position: relative;
}

.viewclientsessionhis-main .viewsession-main .viewsession-body .addbuttons-section {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.viewclientsessionhis-main .viewsession-main .viewsession-body .addbuttons-section .add-btn {
    background-color: var(--primaryBlueColor);
    padding: 4px 10px;
    width: 52px;
    color: var(--white);
    display: block;
    margin: auto;
    border-radius: 20px;
}

.input-box-main {
    border: 2px solid var(--lightBorderColor);
    padding: 25px;
    border-radius: 15px;
    background-color: var(--white);
}

.clientaction-plan-tabs {
    border: 1px solid var(--lightBorderColor);
    padding: 10px;
    border-radius: 10px;
}

.viewclientsessionhis-main .nav-tabs .nav-link:hover {}

.viewclientsessionhis-main .nav-tabs .nav-link {
    border: none !important;
    font-family: "Nunito Sans", sans-serif !important;
    color: var(--headerTextColor) !important;
    font-weight: 600;
    padding: 0;
    margin: 10px auto;
    width: auto !important;


}

.viewclientsessionhis-main .nav-tabs {
    border: 0px !important;
    outline: none !important;
    font-family: "Nunito Sans", sans-serif !important;
    color: var(--headerTextColor) !important;
}


.viewclientsessionhis-main .nav-tabs .nav-link.active {
    color: var(--headerTextColor) !important;
    border-bottom: 3px solid var(--borderOrange) !important;
}

.viewclientsessionhis-main .nav-tabs .nav-link:focus,
.nav-link:active {
    color: var(--headerTextColor) !important;
    border-bottom: 3px solid var(--borderOrange) !important;

}

.viewclientsessionhis-main .nav-tabs .nav-link:focus-visible {
    border-bottom: 3px solid var(--borderOrange) !important;
    color: var(--headerTextColor) !important;
}