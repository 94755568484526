.assessmentcard-main {
    background-color: var(--white);
    border: 2px solid var(--lightBorderColor);
    padding: 10px 20px;
    border-radius: 10px;
    position: relative;
    height: 525px;
}

.assessmentcard-main-empty {
    background-color: var(--white);
    /* border: 2px solid var(--lightBorderColor); */
    padding: 10px 20px;
    /* border-radius: 10px; */
    position: relative;
    height: 10vh;
}

.assessmentcard {
    justify-content: center;
    flex-direction: column;
}

.journalEntryTable .rdt_Table {
    /* height: 27vh !important; */
}

.assessmentcard-main .carousel-control-prev,
.assessmentcard-main .carousel-control-next {
    display: none;
}

.assessment-res-arrows {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
}

.assessmentcard-main .pages-text {
    border: 1px solid var(--primaryBlueColor);
    color: var(--primaryBlueColor);
    padding: 5px;
    border-radius: 20px;
    width: 60px;
    margin: auto;
    text-align: center;
}

.assessmentcard-main .option-text {
    border: 1px solid var(--lightBorderColor);
    padding: 10px 15px;
    margin: 15px auto;
    border-radius: 15px;

}

.text-center-grid {
    display: grid;
    place-items: center;
}

.view-journal-box {
    border-bottom: 1px solid var(--lightBorderColor);
}

.assessmentcard-main .input-answer-text {
    padding: 10px 15px;
    margin: 20px auto;
    border: 1px solid var(--lightBorderColor);
    border-radius: 5px;

}

.assessmentcard-main .option-text.active {
    background-color: var(--lightBlueColor);
}