.dashboardcards-main {
    margin: 20px 0;
}

.dashboardcards-main .dashcard-main {
    background-color: var(--white);
    padding: 20px;
    height: 100%;
    border-radius: 10px;
    box-shadow: 6px 6px 30px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.dashboardcards-main .dashcard-main .card-header {
    color: var(--greyTextColor);
    font-weight: 600;
    margin-bottom: 10px;
}

.dashboardcards-main .dashcard-main .card-value {
    color: var(--headerTextColor);
    font-weight: 700;
}

.dashboardcards-main .dashcard-main .green-text {
    color: var(--greenTextcolor);
}

.dashboardcards-main .dashcard-main .red-text {
    color: var(--redTextcolor);
}


.fc .fc-button-primary {
    background-color: var(--primaryBlueColor) !important;
    color: var(--white) !important;
    border: none !important;
    text-transform: uppercase;
    /* margin-left: 5px !important; */
    border-radius: 15px;
    outline: none !important;
}

.fc .fc-button-primary:hover {
    /* background-color: var(--primaryBlueColor) !important; */
    border-radius: 15px;
    /* margin-left: 5px !important; */
    /* color: var(--white) !important; */
    border: none !important;
    text-transform: uppercase;
    outline: none !important;
}

.fc .fc-col-header-cell a,
.fc a {
    color: var(--headerTextColor);
    font-family: "Nunito Sans", sans-serif;
}

.fc .fc-daygrid-dot-event .fc-event-title {
    font-weight: 400;
    text-align: end;

}

.fc .fc-daygrid-dot-event {
    flex-direction: row-reverse;
    font-family: "Nunito Sans", sans-serif;
}

.fc .fc-daygrid-event-dot {
    border-color: var(--primaryBlueColor);
    background-color: var(--primaryBlueColor);
    height: 6px;
    width: 6px;
}

.fc .fc-scroller-liquid-absolute {
    overflow: auto !important;
}

.fc .fc-button-primary:disabled{
    background: var(--primaryBlueColor) !important;
    opacity: 1;
    color: white !important;
}