.login-main {
    background-color: var(--primaryBlueColor);
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.login-main .logo1 {
    position: absolute;
    left: 0;
    top: 0;
}

.login-main .logo2 {
    position: absolute;
    right: 0;
}

.login-main .logo3 {
    position: absolute;
    left: 0;
    bottom: 0;
}

.login-main .logo4 {
    position: absolute;
    right: 0;
    bottom: 0;
}

.login-main .login-card-main {
    position: relative;
    z-index: 12;
    border-radius: 20px;
    background-color: var(--white);
    padding: 7%;
    margin-top: 20%;
}

.login-main .logo-login {
    width: 220px;
    z-index: 10px;
    position: absolute;
    left: 0;
}

.login-main .login-card-main .login-header h3 {
    font-weight: 800;
    color: var(--headerTextColor);
    font-family: "Nunito Sans", sans-serif;
    margin-bottom: 0;

}

.login-main .login-card-main h6 {
    color: var(--greyTextColor);
    font-family: "Nunito Sans", sans-serif;
    margin-bottom: 15px;
}

.login-main .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(-50%, -50%);
}

.login-main .text-input input {
    padding: 10px;
    background-color: var(--searchBg);
    border-color: var(--lightBorderColor);
    color: var(--inputTextColor);
    font-family: "Nunito Sans", sans-serif;

}

.login-main .text-input input:focus,
.login-main .text-input input:active {
    border-color: var(--lightBorderColor);
    box-shadow: 0 0 0 0rem;

}

.blue-text {
    color: var(--primaryBlueColor);
}

.login-main .btn-login {
    background-color: var(--primaryBlueColor);
    color: var(--white);
    font-family: "Nunito Sans", sans-serif;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
}

.login-main .btn-login:hover {
    background-color: var(--btnBlueHoverColor);
}

.red-error-msg {
    color: var(--redTextcolor);
    font-size: 12px;
    font-family: "Nunito Sans", sans-serif;

}

@media only screen and (max-height: 1000px) {
    .login-main .logo1 {
        position: absolute;
        left: 0;
        top: -15%;
    }

    .login-main .logo2 {
        position: absolute;
        right: 0;
    }

    .login-main .logo3 {
        position: absolute;
        left: 0;
        bottom: -20%;
    }

    .login-main .logo4 {
        position: absolute;
        right: 0;
        bottom: -20%;
    }
}

@media only screen and (max-width: 1200px) {
    .login-main img {
        width: 50%;
    }
}

@media only screen and (max-width: 768px) {
    .login-main .logo-login {
        width: 220px;
        z-index: 10px;
        position: relative;
    }

    .login-main .login-card-main {
        margin-top: 0;
    }

    .login-main {
        overflow-y: auto;
    }

}

@media only screen and (max-width: 568px) {}