.sidebar-main {
    width: 80px;

    transition: width 0.3s ease;
}

.sidebar-main .menuList {
    display: flex;
    height: 90%;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar-main.open {
    width: 250px;
}

.sidebar-main .lower-menu {
    width: 100%;
}

.sidebar-main .sideBarLabels {
    padding: 3px 25px;
}

.sidebar-main .sideBarLabels-collapsed {
    padding: 5px 10px;
    text-align: center;
}

.sidebar-main .sideBarLabels .menu-items {
    padding: 10px 20px;
    margin: 0px 5px;
    border-radius: 5px;
    color: var(--headerTextColor);
    font-weight: 600;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;
}
.sidebar-main .sideBarLabels-collapsed .menu-items {
    padding: 10px 13px;
    margin: 0px 5px;
    border-radius: 5px;
    color: var(--headerTextColor);
    font-weight: 600;
    font-size: 14px;
    font-family: "Nunito Sans", sans-serif;
}

.sidebar-main .sideBarLabels .lower-menu-items {
    padding: 10px 20px;
    margin: 0px 5px;
    border-radius: 5px;
    font-size: 14px;
    color: var(--headerTextColor);
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
}

.sidebar-main .sideBarLabels .menu-items:hover {
    background-color: var(--primaryBlueColor);
    color: var(--white);

}

.sidebar-main .sideBarLabels.active .menu-items {
    background-color: var(--primaryBlueColor);
    color: var(--white);
}

.sidebar-main .sideBarLabels.active:before {
    content: '';
    display: block;
    width: 4px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 45px;
    position: absolute;
    left: 0;
    background-color: var(--primaryBlueColor);

}

.sidebar-main .sideBarLabels-collapsed.active .menu-items {
    background-color: var(--primaryBlueColor);
    color: var(--white);
 
}

.sidebar-main .sideBarLabels-collapsed.active:before {
    content: '';
    display: block;
    width: 4px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    height: 45px;
    position: absolute;
    left: 0;
    background-color: var(--primaryBlueColor);

}

.sidebar-main .hr-light {
    opacity: 1;
    border-top: 1.5px solid var(--LightLineColor);
}