.loader {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.assessmentcard-main .carousel .carousel-indicators {
  display: none;
}

.header-main {
  margin: 10px 0px;
}

.header-main .header-text {
  font-weight: 700;
  color: var(--headerTextColor);
}

/* .Toastify__progress-bar,
.Toastify__progress-bar--bg {
  visibility: none;
  opacity: 0;
} */

.Toastify__progress-bar--wrp {
  /* display: none; */
  visibility: hidden;
}

.close-btn-icon {
  width: 25px;
  height: 25px;
  filter: contrast(50%);
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid var(--blueBorderColor);
}

.container-main {
  width: 100%;
  min-height: 200px;
  background-color: #FFFFFF;
  border-radius: 14px;
  /* padding: 12px; */
  border: 0.5px solid #B9B9B9;
}

.action-btn .green-box {
  font-size: 14px;
  cursor: pointer;
  padding: 5px;
  color: var(--greenTextcolor);
  border-radius: 5px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  background-color: var(--greenBgColor);
}

.action-btn .blue-box {
  font-size: 14px;
  cursor: pointer;
  padding: 5px;
  color: var(--primaryBlueColor);
  border-radius: 5px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  background-color: var(--lightPrimaryBlueColor);
}

.category-dropdown {
  text-align: start !important;
  padding: 10px 10px !important;
}

.category-dropdown option {
  padding: 8px 10px !important;
}

.action-btn .pink-box {
  font-size: 14px;
  cursor: pointer;
  padding: 5px 10px;
  color: var(--pinkColor);
  border-radius: 5px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  background-color: var(--lightPinkColor);
}

.action-btn .blue-box {
  font-size: 14px;
  cursor: pointer;
  padding: 5px;
  color: var(--primaryBlueColor);
  border-radius: 5px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  background-color: var(--lightPrimaryBlueColor);
}

.green-text {
  color: var(--greenTextcolor);
  font-family: "Nunito Sans", sans-serif;

}

.waiting-text {
  color: var(--primaryBlueColor);
  font-weight: 700;
  font-size: 16px;
}

.input-date-type {
  border: 1px solid var(lightBorderColor) !important;
  border-radius: 10px;
  padding: 5px 10px;
  text-transform: uppercase;
  outline: none;
}

.input-date-type ::-webkit-calendar-picker-indicator {
  background-color: transparent;
  color: #333;
  font-size: 18px;
  padding: 2px;
  border: none;
  cursor: pointer;
}

.red-text {
  color: var(--cancelRedColor);
}

.blue-text {
  color: var(--primaryBlueColor);
  font-family: "Nunito Sans", sans-serif;

}


.action-btn .red-box {
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  color: var(--cancelRedColor);
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  background-color: var(--redBgColor);
}

.action-btn .violet-box {
  font-size: 12px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  color: var(--violetTextColor);
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  background-color: var(--violetBgColor);
}

.rdt_Table,
.rdt_TableHeadRow {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-family: "Nunito Sans", sans-serif;

}

.rdt_TableHeadRow {
  font-size: 13px;
  font-weight: 700;
}

.rdt_Pagination {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.rdt_TableCell,
.rdt_TableCol {
  justify-content: center;
}

.rdt_TableCell div:first-child {
  white-space: normal !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.fc-event-time {
  display: none;
}

.captialize {
  text-transform: capitalize;
}

.primary-button {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  border-radius: 12px;
  color: var(--white);
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  background-color: var(--primaryBlueColor);
  border: none;
}

.ghost-button {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  padding: 5px;
  border-radius: 12px;
  color: var(--headerTextColor);
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  background-color: var(--redBgColor);
  border: none;
}

.yesno-toggle-btn .toggle-wrapper {
  position: relative;
  margin: 0px auto;
  width: 100px;
  height: 35px;
  /* font-size: 0.75rem; */
  font-weight: 600;
  font-size: 15px;
  font-family: "Nunito Sans", sans-serif;

}

.yesno-toggle-btn .toggle {
  display: none;
}

.yesno-toggle-btn .foux-toggle {
  position: relative;
  width: 100px;
  height: 35px;
  border-radius: 15px;
  margin: 0;
  background-color: var(--primaryBlueColor);
}

.yesno-toggle-btn .foux-toggle:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 35px;
  background-color: #A1BCF9;
  z-index: 3;
  border-radius: 15px;


}

.yesno-toggle-btn .toggle:checked~.foux-toggle:before {
  border-color: #00a5b5;
  left: 50px;
  border-radius: 15px;

}

.yesno-toggle-btn .toggle--label:before,
.yesno-toggle-btn .toggle--label:after {
  position: absolute;
  top: 0;
  bottom: 0;
  transition: opacity 0.05s;
}

.yesno-toggle-btn .toggle--label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  line-height: 2.3;
  cursor: pointer;
  z-index: 12;
  letter-spacing: 0.0625rem;
  font-weight: 400;
  font-family: "Nunito Sans", sans-serif;

  font-size: 14px;
}

.yesno-toggle-btn .toggle--label:before {
  content: 'Yes';
  left: 25%;
  transform: translateX(-50%);
  color: white;
  opacity: 1;
}

.yesno-toggle-btn .toggle--label:after {
  content: 'No';
  position: absolute;
  right: 0;
  left: 75%;
  transform: translateX(-50%);
  color: white;
  opacity: 1;
}

.yesno-toggle-btn .toggle:checked+.toggle--label:after {
  transition-duration: 0.05s;
}



.datatable.addButtonContainer {
  display: flex;
  justify-content: center;
}

.datatable .addButton {
  background-color: var(--redBgColor);
  color: var(--cancelRedColor);
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

.datatable .addButton:hover {
  background-color: var(--redBgColor);
}