/* Example: Changing the background color of the scheduler header */
.flex-grow-1 {
    width: 100%;
}

/* After importing "devextreme/dist/css/dx.light.css" */
.dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
    color: black !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    height: 100px !important;
}

.dx-scheduler-date-table-other-month {
    background-image:
        repeating-linear-gradient(135deg,
            rgba(244, 67, 54, 0.1),
            rgba(244, 67, 54, 0.1) 4px,
            transparent 4px,
            transparent 9px);
    color: #9b6467;
}

.dx-button-mode-contained.dx-state-selected {
    background-color: #5A8DFF !important;
    color: white !important;
}

.dx-button-mode-contained {
    background-color: #FAFBFD !important;
}

/* Target FullCalendar date cells */
.fc-day,
.fc-daygrid-day {
    cursor: pointer;
}


.fc-toolbar-chunk {
    display: flex;
    align-items: center;
}

.fc-direction-ltr .fc-toolbar>*> :not(:first-child) {
    margin-left: 0.2em;
}

.fc .fc-popover {
    z-index: 1000;
}

/* .fc .fc-day-other .fc-daygrid-day-top {
    background-image:
        repeating-linear-gradient(135deg,
            rgba(244, 67, 54, 0.1),
            rgba(244, 67, 54, 0.1) 4px,
            transparent 4px,
            transparent 9px);
    color: #9b6467;
} */

/* .fc .fc-daygrid-day.fc-day-today {
    background-color: transparent !important;
} */

/* Style for the selected date */
.selected-date {
    background-color: #a3d9a5 !important;
}

.fc-h-event {
    opacity: 0.8;
}

.bttitl {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 12px;
    width: 100%;
}

.selectedv {
    background-color: #66bb6a !important;
    /* background-color: var(--primaryBlueColor) !important; */
    width: 100%;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    border-radius: 12px !important;
    color: var(--white) !important;
}

.non-selectedv {
    /* background-color: #66bb6a !important; */
    border: 1px solid #66bb6a !important;
    width: 100%;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    border-radius: 12px !important;
    color: #66bb6a !important;
}


.onecontent {
    width: 100%;
    display: flex;
}

.twocontent {
    width: 100%;
    display: flex;
    gap: 5px
}

.w-60 {
    width: 60%;
}

.w-40 {
    width: 50%;
    padding-inline: 30px;
}

.w-50 {
    width: 50%;
}

.toggleButtons button {
    width: 50%;
    height: 50px;
    outline: none;
    border: none;
    background-color: #FBFBFB;
    color: #6F87A6;
    border-color: #CFD7E0;
    /* max-width: 220px;
    min-width: 220px; */
}

.toggleButtons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap
}

.copy-toggleButtons button {
    width: 100%;
    height: 50px;
    outline: none;
    border: none;
    background-color: #FBFBFB;
    color: #6F87A6;
    border-color: #CFD7E0;
    white-space: nowrap;
    /* max-width: 170px;
    min-width: 170px; */
}

.copy-toggleButtons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    /* flex-wrap: wrap */
}

.durationtime {
    margin-top: 15px;
}

.pickDate {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.picks p {
    font-size: 16px;
    font-weight: bold;
}

.confirm {
    width: 100%;
    max-width: 170px;
    min-width: 170px;
    height: 45px;
    outline: none;
    border: none;
    background-color: var(--primaryBlueColor);
    /* background-color: #66bb6a !important; */
    color: white;
    border-color: #CFD7E0;
    border-radius: 4px;
    padding-inline: 12px;
    font-size: 14px;
    font-weight: bold;
}

.confirm-grp {
    margin-top: 7%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.startend {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.startendbox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
}

.startendbox label {
    font-size: 13px;
    font-weight: bold;
}

.tymselect {
    /* width: 285px; */
    height: 45px;
    outline: none;
    border: none;
    box-shadow: none;
}

.addnew-grp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.addnew {
    margin-top: 12px;
    width: 100%;
    height: 45px;
    outline: none;
    border: none;
    background-color: var(--primaryBlueColor);
    /* background-color: #66bb6a !important; */
    color: white;
    border-color: #CFD7E0;
    border-radius: 4px;
    padding-inline: 12px;
    font-size: 14px;
    font-weight: bold;
}

.button {
    position: relative;
    /* Ensure the button is positioned relatively so the icon can be positioned absolutely within it */
}

.check-icon {
    /* position: absolute;
    top: 5px;
    right: 12px; */
    margin-inline-start: 7px;
}

.updateSchedule-button {
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    padding: 12px;
    border-radius: 12px;
    color: var(--white);
    font-weight: 600;
    background-color: var(--primaryBlueColor);
    border: none;
}

.delete-icon {
    font-size: 20px;
    color: red;
}

.fc-button[aria-pressed="true"] {
    background-color: var(--primaryBlueColor) !important;
}

.fc-button[aria-pressed="false"] {
    background-color: #FAFBFD !important;
    color: black !important
}

.slotTimedisplay {
    background-color: var(--primaryBlueColor);
    color: #ffffff;
    padding: 2px;
    gap: 5px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: space-around;
}