.greenbox {
    background-color: #CCF0EB;
    color: #00B69B;
    width: 80px;
    border-radius: 5px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.greentext {
    color: #00B69B;
    font-weight: bold;
    font-size: 12px;
}

.text-area-container {
    position: relative;
}

.button-container {
    position: absolute;
    bottom: 10px;
    right: 15px;
    display: flex;
    justify-content: end;
    gap: 4px
}

.button-container-profile {
    display: block;
}

.save-btn {
    background-color: var(--primaryBlueColor);
    color: white;
    border-radius: 10px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    width: 62px;
    height: 27px;
}

.btn-back {
    background-color: var(--primaryBlueColor);
    color: white;
    border-radius: 10px;
    border: none;
    font-size: 12px;
    font-weight: bold;
    width: 62px;
    height: 27px;
}

.previous-title {
    color: #6CC3B8;
    font-size: 21px;
    line-height: 25px
}

.previous-items {
    color: #6A7387 !important;
    font-size: 15px;
    line-height: 25px
}