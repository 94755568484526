.cardcontent {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.txtBtn {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.spantxt {
  width: 100px;
  color: #202224;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
}


