.card-title {
  color: #202224;
  font-size: 16px;
  font-weight: 500;
}

.card-count {
  color: #202224;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 1px;
}

.card-redtext {
  color: #f93c65;
  font-size: 16px;
  font-weight: 500;
}

.card-greytext {
  color: #606060;
  font-size: 16px;
  font-weight: 500;
}

.card-greentext {
  color: #00b69b;
  font-size: 16px;
  font-weight: 500;
}
