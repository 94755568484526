.signup-main {
    background-color: var(--primaryBlueColor);
    height: 100vh;
    overflow: auto;
}

.signup-main .logo-section img {
    width: 230px;
}

.signup-main .card-main {
    border-radius: 20px;
    background-color: var(--white);
    padding: 7%;
    margin-bottom: 5%;
}

.signup-main .card-main .signup-header h3 {
    font-weight: 800;
    color: var(--headerTextColor);
    font-family: "Nunito Sans", sans-serif;
    margin-bottom: 0;

}

.signup-main .card-main h6 {
    color: var(--greyTextColor);
    font-family: "Nunito Sans", sans-serif;
    margin-bottom: 15px;
}

.signup-main .dropdown .dropdown-toggle {
    background-color: var(--searchBg);
    color: var(--headerTextColor);
    font-family: "Nunito Sans", sans-serif;
    width: 100%;
    border: 1px solid var(--lightBorderColor);
}

.signup-main .dropdown .dropdown-menu {
    right: 50%;
    left: 35% !important;
    padding: 20px;
}

.signup-main .btn-signup {
    background-color: var(--primaryBlueColor);
    color: var(--white);
    font-family: "Nunito Sans", sans-serif;
    padding: 10px;
    border-radius: 10px;
    font-weight: 600;
}

.signup-main .btn-signup:hover {
    background-color: var(--btnBlueHoverColor);
}

.signup-main .text-input input {
    padding: 10px;
    background-color: var(--searchBg);
    border-color: var(--lightBorderColor);
    color: var(--inputTextColor);
    font-family: "Nunito Sans", sans-serif;
}
.signup-main .text-input textarea {
    padding: 10px;
    background-color: var(--searchBg);
    border-color: var(--lightBorderColor);
    color: var(--inputTextColor);
    font-family: "Nunito Sans", sans-serif;
}

.login-main .text-input input:focus,
.login-main .text-input input:active {
    border-color: var(--lightBorderColor);
    box-shadow: 0 0 0 0rem;

}