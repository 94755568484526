.dashboard-main .header-dropdown {
    font-size: 28px;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
    border: 0px;
    background-color: transparent;
    cursor: pointer;

}

.calender-card-main {
    background-color: var(--white);
    border-radius: 20px;
    border: 1px solid var(--lightBorderColor);
    padding: 10px 20px;
}

.hover-box .start-session-btn {
    border-radius: 20px;
    border: 1px solid var(--lightBorderColor);
    font-family: "Nunito Sans", sans-serif !important;
    padding: 5px 10px;
    font-size: 12px;
    color: #00B69B;
}

.hover-box .start-message-btn:hover {
    border: 1px solid var(--lightBorderColor);

}

.hover-box .start-session-btn:hover {
    border: 1px solid var(--lightBorderColor);

}

.hover-box .event-cancel-btn:hover {
    border: 1px solid #fc8484;
    color: #fc8484;

}

.hover-box .event-cancel-btn {
    border-radius: 20px;
    border: 1px solid#fc8484;
    font-family: "Nunito Sans", sans-serif !important;
    padding: 5px 10px;
    font-size: 12px;
    color: #FF0000;
    cursor: pointer;
}


.hover-box .start-message-btn {
    border-radius: 20px;
    border: 1px solid var(--lightBorderColor);
    font-family: "Nunito Sans", sans-serif !important;
    padding: 5px 10px;
    font-size: 12px;
    color: #4880FF;
    cursor: pointer;
}

.dashboard-main .header-dropdown option {
    cursor: pointer;
}

.dashboard-main .content-tab {
    margin: 15px 0;
}

.dashboard-main .toggle-icon .img-btn {
    padding: 0px 5px;
}

.dashboard-main .toggle-icon .img-btn img {
    width: 35px;
}

.black-text {
    color: #202224 !important;
}

.textarea-event-cal {
    border: 1px solid var(--lightBorderColor);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    pointer-events: none;
    font-family: "Nunito Sans", sans-serif !important;
    font-size: 12px;
    color: #202224
}

.dashboard-main .toggle-icon .img-btn>input {
    display: none
}

.dashboard-main .toggle-icon .img-btn>img {
    cursor: pointer;
}

.dashboard-main .orange-border {
    border-bottom: 2px solid var(--borderOrange);
}

.view-appointment .title {
    font-size: 20px;
    font-weight: 700;
    position: relative;
}

.view-appointment .title .fa-xmark {
    color: #fff;
    position: absolute;
    width: 45px;
    height: 45px;
    background: #000;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -38px;
    top: -35px;
    cursor: pointer;
}

.view-appointment .appointment-details {
    row-gap: 13px;
    margin-block-end: 7%;
}

.view-appointment .appointment-details .sub-title {
    font-weight: 600;
}