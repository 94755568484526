 html,
 body {
   margin: 0;
   padding: 0;
   overflow-x: hidden;
   overflow-y: hidden;
   font-family: "Nunito Sans", sans-serif;
 }




 #root {
   overflow-y: hidden;
 }

 p,
 a,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   font-family: "Nunito Sans", sans-serif;
 }

 a {
   text-decoration: none !important;
 }

 span {
   font-family: "Nunito Sans", sans-serif;

 }

 .btn,
 label,
 input {
   font-family: "Nunito Sans", sans-serif !important;
 }

 :root {
   --searchBg: #F1F4F9;
   --inputTextColor: #A6A6A6;
   --headerTextColor: #202224;
   --greyTextColor: #565656;
   --lightBorderColor: #D8D8D8;
   --blueBorderColor: #C4E9E7;
   --lightBlueColor: #C4E9E7;
   --primaryBlueColor: #4880FF;
   --btnBlueHoverColor: #E1E6FF;
   --white: #FFFFFF;
   --LightLineColor: #E0E0E0;
   --greenBgColor: #CCF0EB;
   --greenTextcolor: #00B69B;
   --violetBgColor: #E3D9FA;
   --violetTextColor: #6226EF;
   --redBgColor: #FFE3E1;
   --cancelRedColor: #EF3826;
   --redTextcolor: #F93C65;
   --pinkColor: #FE3DD4;
   --lightPinkColor: #FFD8F6;
   --borderOrange: #FB7C1C;
   --lightWhite: #FCFDFD;
   --lightPrimaryBlueColor: #DAE6FF;

 }



 @import "./assets/css/Login.css";
 @import "./assets/css/AppHeader.css";
 @import "./assets/css/AppSidebar.css";
 @import "./assets/css/DashboardCards.css";
 @import "./assets/css/Dashboard.css";
 @import "./assets/css/Signup.css";
 @import "./assets/css/ForgorPassword.css";
 @import "./assets/css/Dropdown.scss";
 @import "./assets/css/Profile.scss";
 @import "./assets/css/Community.scss";
 @import "./assets/css/ComunityJoin.scss";
 @import "./assets/css/BillingOverview.scss";
 @import "./assets/css/StartSession.css";
 @import "./assets/css/ViewClient.css";
 @import "./assets/css/ViewClientSessionHis.css";
 @import "./assets/css/StatSession.css";
 @import "./assets/css/Availability.css";
