.checkbox-dropdown {
    position: relative;
    font-size: 14px;
    color: var(--headerTextColor);
    font-family: "Nunito Sans", sans-serif;

    .dropdown-list {
        padding: 12px;
        background: #fff;
        position: absolute;
        z-index: 9;
        cursor: pointer;
        top: 45px;
        left: 2px;
        right: 2px;
        box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .15);
        transform-origin: 50% 0;
        transform: scale(1, 0);
        transition: transform .15s ease-in-out .15s;
        max-height: 35vh;
        overflow-y: scroll;
    }

    .dropdown-option {
        display: block;
        padding: 8px 12px;
        cursor: pointer;
        opacity: 0;
        transition: opacity .15s ease-in-out;
    }

    .dropdown-label {
        display: block;
        background-color: var(--searchBg);
        color: var(--inputTextColor);
        border-radius: 5px;
        cursor: pointer;
        border: 1px solid var(--lightBorderColor);
        font-family: "Nunito Sans", sans-serif;

        padding: 15px;
        line-height: 1;
        cursor: pointer;

    }

    &.on {
        .dropdown-list {
            transform: scale(1, 1);
            transition-delay: 0s;

            .dropdown-option {
                opacity: 1;
                transition-delay: .2s;
            }
        }


    }

    [type="checkbox"] {
        position: relative;
        top: 2px;
        cursor: pointer;
        margin-right: 5px;
    }
}

.dropdown-search-input {
    border-radius: 5px;
    height: 35px;
    border-width: 1px;
    width: 100%;
    outline: none;
    border: 1px solid var(--lightBorderColor);

}

.dropdown-search-input:focus,
.dropdown-search-input:active,
.dropdown-search-input:focus-visible {
    border: 1px solid var(--lightBorderColor);
    outline: none;
}