.header-main .main {
    width: 50%;
    margin: 50px auto;
}

.has-search {
    position: relative;
    width: 30%;
}

.header-main {
    padding: 0.5rem 2rem 0.5rem 0.4rem;
}

.has-search .form-control {
    padding-left: 2.375rem;
    /* background-color: var(--searchBg); */
    height: 40px;
    border-radius: 10px;
    width: 100%;
    color: var(--headerTextColor);
    font-family: "Nunito Sans", sans-serif;

}

.has-search .form-control:focus,
.has-search .form-control:active {
    border-color: var(--lightBorderColor);
    box-shadow: 0 0 0 0rem;

}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    color: var(--headerTextColor);
    pointer-events: none;
}

.header-main .lang-dropdown {
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
}

.header-main .lang-dropdown .btn:active {
    border: 0px;

}

.header-main .lang-dropdown .btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    border: 0px;
}


.header-main .profile-image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.header-main .specialist-text {
    color: var(--greyTextColor);
    font-size: 12px;
}


.header-main .profile-down-arrow i {
    font-size: 12px;
    border: 1px solid var(--lightBorderColor);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
}