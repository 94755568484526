.communityheading {
  color: #0c0c2c;
  font-size: 30px;
  font-weight: bolder;
  text-transform: capitalize;
}
.communityheadingcontainer {
  margin-top: -10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.contentbox {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.1) -3px 0px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.cardDesc {
  font-size: 16px;
  letter-spacing: 0.5px;
}

.BtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 30px;
}

.container-align {
  padding: 25px;
}

.joinBtn {
  color: black;
  background-color: white;
  border: 1px solid #fb7c1c;
  border-radius: 28px;
  outline: none;
  padding: 10px;
  font-size: 15px;
}

.joinBtn1 {
  color: black;
  background-color: white;
  border: 1px solid #fb7c1c;
  border-radius: 28px;
  outline: none;
  padding: 10px;
  font-size: 15px;
  display: none;
}

.Joined-headingcontainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.Joined-heading {
  color: #0c0c2c;
  font-size: 30px;
  font-weight: bolder;
  text-transform: capitalize;
}

.Joined-contentbox {
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) -3px 0px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 12px;
  margin-bottom: 30px;
}

.Joined-contentbox:hover .joinBtn1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Joined-textwithicon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.Joined-text {
  font-size: 15px;
  color: #000000;
  margin-bottom: 12px;
}

.Joined-clock {
  color: #fb7c1c;
  font-size: 12px;
  font-weight: 600;
}

.subtextwithicons {
  display: flex;
  align-items: center;
  gap: 2px;
}

.text-and-send {
  position: relative;
  display: flex;
}

.text-and-send textarea {
  flex: 1;
  border: 1px solid #fb7c1c;
  padding: 10px;
  outline: none;
  border-radius: 12px;
  resize: none;
}

.text-and-send button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
}


