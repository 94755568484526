.labels {
  color: #adadad;
  font-size: 14px;
}

.inputBox {
  background-color: #f5f6fa !important;
  width: 100% !important;
  height: 52px !important;
}

.inputTextBox {
  background-color: #f5f6fa !important;
  width: 100% !important;
  resize: none;
}

.inputBox:focus {
  box-shadow: none !important;
  border-color: #d5d5d5 !important;
}

.inputBox-small {
  background-color: #f5f6fa !important;
  width: 75% !important;
  height: 52px !important;
}

.inputBox-small:focus {
  box-shadow: none !important;
  border-color: #d5d5d5 !important;
}

.inputBox-small-xs {
  background-color: #f5f6fa !important;
  width: 25% !important;
  height: 52px !important;
}

.inputBox-small-xs:focus {
  box-shadow: none !important;
  border-color: #d5d5d5 !important;
}

.editbutton {
  font-size: 14px;
  color: #4379ee;
  letter-spacing: 0.54px;
  line-height: 16px;
}

.button-container {
  width: 200px;
  margin: 0 auto;
}